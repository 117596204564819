<template>
  <c-tab
    type="horizon"
    :dense="true"
    :tabItems.sync="tabItems"
    :inlineLabel="true"
    :height="tabHeight"
    v-model="tab"
  >
    <template v-slot:default="tab">
      <component
        :is="tab.component"
        :popupParam="popupParam"
        :dangerStore="dangerStore"
        :attachInfo="attachInfo"
        :isOld="isOld"
        :contentHeight.sync="contentHeight"
        @getDetail="getDetail"
        @closePopup="closePopup"
      />
    </template>
  </c-tab>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'dangerMasterDetail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        chmDangerMstId: '',
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      tab: 'info',
      dangerStore: {
        chmDangerMstId: '',  // 위험물 저장소 일련번호
        plantCd: null,  // 사업장 코드
        chmDangerArea: '',  // 설치장소
        chmDangerTypeCd: null,  // 저장소 구분
        installPermitDate: '',  // 설치허가일
        permitNo: '',  // 허가번호
        chmDangerManageUserId: '',  // 안전관리자
        chmDangerMstName: '',  // 품명
        chmDangerUserId: '',  // 담당자
        chmDangerSafetyUserId: '',  // 안전대리자
        chmDangerCount: '',  // 지정수량 문자열
        chmDangerMainUse: '',  // 주용도
        useFlag: 'Y',  // 사용여부
        regularCheckFlag: 'N',  // 정기점검 대상여부
        preventRegularFlag: 'N',  // 예방 규정 대상 여부
        regUserId: '',  // 등록자 ID
        chgUserId: '',  // 수정자 ID
        typeList: [],
        deleteTypeList: [],
        itemList: [],
        fireFightings: [],
      },
      attachInfo: {
        isSubmit: '',
        taskClassCd: 'DANGER_PREVENT_REGULAR',
        taskKey: '',
      },
      editable: true,
      detailUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '50%',
        top: '10px',
        param: {},
        closeCallback: null,
      },
    };
  },
  computed: {
    isOld() {
      return Boolean(this.popupParam.chmDangerMstId)
    },
    tabHeight() {
      return String(this.contentHeight - 36);
    },
    tabItems() {
      let _items = [
        { name: 'info', icon: 'info', label: 'LBLBASEINFO', component: () => import(`${'./dangerMasterInfo.vue'}`) },
      ]
      if (this.isOld) {
        _items.push({ name: 'item', icon: 'construction', label: '부가정보(점검항목, 소방시설)', component: () => import(`${'./dangerMasterEtc.vue'}`) })
      }
      return _items;
    }
  },
  watch: {
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.chm.danger.master.get.url;
      // list setting
      this.getDetail();
    },
    getDetail() {
      if (this.isOld) {
        this.$http.url = this.$format(this.detailUrl, this.popupParam.chmDangerMstId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.$_.extend(this.dangerStore, _result.data)
          this.$set(this.attachInfo, 'taskKey', this.popupParam.chmDangerMstId);
          // 상세팝업 최초작성/최근수정 정보 노출
          this.$emit('setRegInfo', _result.data)
        },);
      }
    },
    closePopup() {
      this.$emit('closePopup')
    }
  }
};
</script>
